body {
	font-family: "Rubik", sans-serif;
	font-weight: 400;
	position: relative;
	min-height: 100vh;
}

img {
	max-width: 100%;
	height: auto;
}

.header-area {
	position: relative;
	text-align: center;
	padding: 15px 0 10px;
	overflow: hidden;
}

.header-area .line {
	background: url(./assets/img/icon-1.png) no-repeat scroll 0 0 / 100%;
	width: 75px;
	height: 100px;
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
}

.header-area:before {
	content: "";
	background: #2cabe1;
	position: absolute;
	height: 2000px;
	width: 2500px;
	left: 50%;
	-webkit-clip-path: ellipse(40% 35% at 50% 50%);
	clip-path: ellipse(40% 35% at 50% 50%);
	top: -1525px;
	margin-left: -1250px;
	z-index: 2;
}

.header-area:after {
	content: "";
	background: url(./assets/img/icon-2.png) no-repeat scroll left bottom / 100%;
	position: absolute;
	height: 100%;
	left: 0;
	width: 70px;
	z-index: 1;
	bottom: 180px;
}

.logo {
	position: relative;
	width: 113px;
	margin: 0 auto 25px;
	z-index: 2;
}

.logo h2 {
	font-family: "Neometric Alt";
	color: #fff;
	text-align: left;
	position: absolute;
	bottom: -10px;
	letter-spacing: 1px;
	font-size: 8px;
}

.author {
	background: #ffff;
	width: 100px;
	height: 100px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 50%;
	overflow: hidden;
	box-shadow: -1px 3px 5px rgb(0 0 0 / 45%);
	margin: auto;
	position: relative;
	z-index: 2;
}

.author img {
	max-width: 100px;
}

.main-menu {
	position: relative;
	z-index: 2;
}

.main-menu ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: auto;
	padding: 0;
	list-style: none;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	max-width: 420px;
}

.main-menu ul li {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 33.33%;
	flex: 0 0 33.33%;
}

.main-menu ul li a {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin: auto;
	text-align: center;
	margin-bottom: 30px;
	display: block;
	color: #000002;
	font-size: 12px;
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

.main-menu ul li a .icon {
	background: #272361;
	margin: auto;
	text-align: center;
	width: 50px;
	height: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 10px;
	margin-bottom: 5px;
}

.main-menu ul li a:hover {
	color: #2cabe1;
	text-decoration: none;
}

.main-menu ul li a:hover .icon {
	background: #2cabe1;
}

.main-menu ul li a .icon img {
	max-width: 25px;
	max-height: 25px;
}

.section-area {
	padding: 0;
	position: relative;
	min-height: calc(100vh - 280px);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

body:before {
	content: "";
	background: url(./assets/img/icon-4.png) no-repeat scroll left bottom / 100%;
	width: 150px;
	height: 160px;
	position: absolute;
	bottom: 0;
}

body:after {
	content: "";
	background: url(./assets/img/icon-5.png) no-repeat scroll right bottom / 100%;
	width: 270px;
	height: 260px;
	position: absolute;
	bottom: 0;
	right: 0;
}

.share {
	background: url(./assets/img/icon-3.png) no-repeat scroll 0 0 / 100%;
	position: absolute;
	left: 5px;
	top: 115px;
	width: 25px;
	height: 30px;
}

.author-info {
	margin-top: 20px;
	font-size: 15px;
}

.author-info h2 {
	color: #14a1db;
	font-weight: 600;
	margin: 0;
	font-size: 25px;
}

.author-info p {
	margin: 0;
	color: #2b2559;
}

.project {
	display: flex;
	align-items: center;
	justify-content: center;
}

.project img {
	width: 160px;
	margin: 10px;
	padding: 10px;

	background: #fafafa;
	border-radius: 8px;
}

@media screen and (max-width: 767px) {
	body:after {
		width: 150px;
	}
	body:before {
		width: 120px;
	}

	.author {
		width: 100px;
		height: 100px;
	}
	.author img {
		max-width: 100%;
		height: 100%;
	}

	.section-area {
		margin-top: 0px;
	}
}
