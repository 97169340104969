a{ 
    cursor: pointer;
}

.save-cursor{
    
}

.logo img {
    
}

.logo{
   width: 185px
}

.section-area {
   padding: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    position: absolute;
     min-height: calc(100vh - 323px);
}

.select-language-area{
   position: absolute;
   right: 0;
   z-index: 9;
   margin: 4px;
}

.language_link{
   display: none;
}